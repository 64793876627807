<template>
  <div class="container-fluid">

    <div class="not_found_container">
      <a href="/">
        <div class="not_found">

</div>
      </a>
         
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style>

   .not_found_container {
        width: 100%;
        min-height: 450px;
   }

    .not_found {
        margin-top: 20px;
        width: 100%;
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(https://img.freepik.com/premium-vector/404-error-page-found-banner-with-cable-socket-cord-plug-website-blue_249405-130.jpg?w=2000g)
    }

</style>